// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDyoHRt05jMxdCPPufT9HtDQSXa9uC7jM0",
  authDomain: "dorightnow-netlify.firebaseapp.com",
  databaseURL: "https://dorightnow-netlify.firebaseio.com",
  projectId: "dorightnow-netlify",
  storageBucket: "dorightnow-netlify.appspot.com",
  messagingSenderId: "116197677386",
  appId: "1:116197677386:web:feb51d78e085bf155a89b3",
  measurementId: "G-J2XR7D85PF",
};
